import TagManager from "react-gtm-module";

export default function useGtmEvent() {
  const trackEvent = args => {
    if (!process.env.REACT_APP_GTM_ID) {
      return;
    }

    const dataLayer = {
      event: "CustomEvent"
    };

    if (args) {
      Object.assign(dataLayer, args);
    }

    TagManager.dataLayer({
      dataLayer
    });
  };

  return {
    trackEvent
  };
}
