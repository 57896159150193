import classNames from "classnames";
import { hu } from "i18n/hu";
import styles from "./ProductDetailsTable.module.scss";

export default function ProductDetailsTable({ product, expand }) {
  const { is_sale, price, material_requirements, surface, guarantee } = product;

  const { salePriceLabel, priceLabel, materialRequirementsLabel, surfaceLabel, guaranteeLabel } = hu.productDetails;

  const { module, separator, highlight, materialRequirementsWrapper, piecesSize, expanded, hide } = styles;

  const materialRequirements = material_requirements.match(/([-0-9,.\s–]+)(.*)+/);

  return (
    <div className={classNames(module, { [expanded]: expand })}>
      <div>
        <p>{is_sale ? salePriceLabel : priceLabel}</p>
        <span className={classNames({ [highlight]: is_sale })}>{price}</span>
      </div>
      <span className={classNames(separator, { [hide]: expand })}></span>
      <div>
        <p>{materialRequirementsLabel}</p>
        <span className={materialRequirementsWrapper}>
          <span>
            {materialRequirements[1]} <span className={piecesSize}>{materialRequirements[2]}</span>
          </span>
        </span>
      </div>
      {expand && (
        <>
          <div>
            <p>{surfaceLabel}</p>
            <span>{surface}</span>
          </div>
          <div>
            <p>{guaranteeLabel}</p>
            <span>{guarantee}</span>
          </div>
        </>
      )}
    </div>
  );
}
