import React from "react";
import ReactDOM from "react-dom/client";
import TagManager from "react-gtm-module";
import "./index.css";
import "./assets/styles/main.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

if (process.env.REACT_APP_GTM_ID) {
  TagManager.initialize({
    gtmId: process.env.REACT_APP_GTM_ID
  });
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
