import { useState, useEffect } from "react";
import { useSwipeable } from "react-swipeable";
import classNames from "classnames";
import { useApiService } from "hooks/apiService";
import { useAppState } from "hooks/appState";
import useGtmEvent from "hooks/gtmTrack";
import { hu } from "i18n/hu";
import { Loader, BadgeItem, ProductDetailsTable, Button, ReferencesCarousel } from "components";
import styles from "./ProductDetails.module.scss";

export default function ProductDetails() {
  const { get } = useApiService();
  const [product, setProduct] = useState();
  const [isZenit, setIsZenit] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [showBox, setShowBox] = useState(false);
  const [expand, setExpand] = useState();
  const [appState, setAppState] = useAppState();
  const { trackEvent } = useGtmEvent();

  const {
    module,
    overlay,
    box,
    animShow,
    animHide,
    badgeWrapper,
    buttonWrapper,
    expanded,
    expandButton,
    closeButton,
    loaderWrapper,
    contentWrapper,
    hide,
    zenitInfo,
    hasZenitInfo
  } = styles;

  const icon = "icon-up";

  useEffect(() => {
    let isLoading = false;

    const fetchProductDetails = async () => {
      if (isLoading) {
        return;
      }

      isLoading = true;
      try {
        const { data: productDetails } = await get("product-variations", null, {
          variation_id: appState.currentProduct
        });
        setProduct(productDetails.data);
        setIsZenit(productDetails.data.family.toLowerCase().startsWith("zenit"))
        return Promise.resolve();
      } catch (error) {
        console.log(error);
      } finally {
        isLoading = false;
      }
    };

    setShowOverlay(true);
    setShowBox(true);
    fetchProductDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    setShowBox(false);
    setShowOverlay(false);

    setTimeout(() => setAppState({ currentProduct: null, swipeBoxVisible: false }), 200);
  };

  const showDetails = () => {
    setExpand(true);

    trackEvent({
      event: "TileOpened",
      category: "cserép",
      action: "részletek",
      label: product.name
    });
  };

  const handlers = useSwipeable({
    onSwipedUp: showDetails,
    preventScrollOnSwipe: true,
    trackMouse: true
  });

  const handleFavourites = () => {
    if (appState.favourites.includes(product.id)) {
      return false;
    }
    setAppState({ favourites: [...appState.favourites, product.id] });

    trackEvent({
      event: "AddToFavourites",
      category: "cserép",
      action: "kedvencekhez adás",
      label: product.name
    });
  };

  const handleOutBoundLink = () => {
    trackEvent({
      event: "TileLink",
      category: "cserép",
      action: "outbound click",
      label: product.link
    });
  };

  return (
    <div className={classNames(module, { [overlay]: showOverlay })}>
      <div className={classNames(box, showBox ? animShow : animHide, { [expanded]: expand }, { [hasZenitInfo]: isZenit })} {...handlers}>
        {product ? (
          <>
            <div className={contentWrapper}>
              <h1>{`${product.family} ${product.name}`}</h1>
              <h2>{product.family_subtitle}</h2>
              <img src={product.image} alt={product.name} />
              <div className={badgeWrapper}>
                {product.badges.map((item, index) => (
                  <BadgeItem key={index} badge={item} />
                ))}
              </div>
              {isZenit &&
                <p className={zenitInfo} dangerouslySetInnerHTML={{ __html: hu.zenitInfo }} />
              }
              <ProductDetailsTable product={product} expand={expand} />
              <div className={buttonWrapper}>
                {!expand && <Button label="moreInfo" color="red" onClick={() => setExpand(true)} />}
              </div>
              {expand && (
                <>
                  <ReferencesCarousel />
                  <div className={buttonWrapper}>
                    <Button label="productSheet" externalLink={product.link} color="red" onClick={handleOutBoundLink} />
                    <Button label="addToFavourites" onClick={handleFavourites} />
                  </div>
                </>
              )}
            </div>
          </>
        ) : (
          <div className={loaderWrapper}>
            <Loader favicon={true} />
          </div>
        )}
      </div>
      <span className={classNames(expandButton, icon, { [hide]: expand })} onClick={showDetails}></span>
      <span className={classNames(closeButton, "icon-previous")} onClick={handleClose}></span>
    </div>
  );
}
