import { useState, useEffect } from "react";
import { useApiService } from "hooks/apiService";
import { useAppState } from "hooks/appState";
import { Loader } from "components";
import Carousel, { CarouselItem } from "./Carousel";
import { hu } from "i18n/hu";
import styles from "./ReferencesCarousel.module.scss";

export default function ReferencesCarousel() {
  const { get } = useApiService();
  const [isLoading, setIsLoading] = useState(false);
  const [appState] = useAppState();
  const [references, setReferences] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [lastItem, setLastItem] = useState(false);
  const [hasError, setHasError] = useState(false);

  const { module, loaderWrapper } = styles;

  useEffect(() => {
    const fetchReferences = async () => {
      if (isLoading) {
        return;
      }

      setIsLoading(true);

      try {
        const { data: references } = await get("product-variations/references", null, {
          variation_id: appState.currentProduct
        });
        setReferences(references.data);
        return Promise.resolve();
      } catch (error) {
        console.log(error);
        setHasError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchReferences();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={module}>
      {isLoading && (
        <div className={loaderWrapper}>
          <Loader favicon={true} />
        </div>
      )}
      {hasError && <div dangerouslySetInnerHTML={{ __html: hu.errors.serverError }}></div>}
      {references.length > 0 && (
        <Carousel references={references} setLastItem={setLastItem}>
          {references?.map(item => {
            return (
              <CarouselItem key={item.id}>
                <img src={item.image} alt={item.title} className={item.link && styles.blur} />
                {item.link && (
                  <a
                    href={item.link.url}
                    className={styles.moreReferences}
                    {...(item.link.target && `target="${item.link.target}" rel="noreferrer"`)}>
                    {item.link.label}
                  </a>
                )}
              </CarouselItem>
            );
          })}
        </Carousel>
      )}
    </div>
  );
}
