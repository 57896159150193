import classNames from "classnames";
import styles from "./FavouritesListItem.module.scss";
import { hu } from "i18n/hu";

export default function FavouritesListItem({
  id,
  name,
  image,
  price,
  net_price,
  surface,
  material_requirements,
  removeItem
}) {
  return (
    <table className={styles.module}>
      <tbody>
        <tr>
          <td>
            <div className={styles.main}>
              <img src={image} alt={name} />
              <div>
                {name.split(" ").map((item, index) => (
                  <span key={index} className={classNames({ "font-bold": index === 0 })}>
                    {item}
                  </span>
                ))}
              </div>
            </div>
          </td>
          <td>
            <div className={styles.prices}>
              <span className="font-bold text-base">{price}</span>
            </div>
          </td>
          <td>
            <em className="icon-trash cursor-pointer" onClick={() => removeItem(id)}></em>
          </td>
        </tr>
        <tr>
          <td>{hu.productDetails.surfaceLabel}</td>
          <td>{surface}</td>
          <td>&nbsp;</td>
        </tr>
        <tr>
          <td>{hu.productDetails.materialRequirementsLabel}</td>
          <td>{material_requirements}</td>
          <td>&nbsp;</td>
        </tr>
      </tbody>
    </table>
  );
}
