export const hu = {
  logo: "Terrán logo",
  onBoarding: {
    swipe: "A teljes állvány megtekintéséhez húzza balra a képernyőt!",
    touch: "További információkért koppintson a cserépre!"
  },
  page404: {
    title: "Sajnáljuk",
    content: "de a keresett oldal nem található szerverünkön!",
    full: "Sajnáljuk, de a keresett oldal nem található szerverünkön!"
  },
  footer: {
    copyright: "Copyright © 2023 Minden jog fenntartva.",
    links: [
      {
        url: "https://terranteto.hu/adatkezelesi-tajekoztato/",
        label: "Adatkezelés",
        target: "_blank",
      },
      {
        url: "https://terranteto.hu/cookie-tajekoztato/",
        label: "Cookie tájékoztató",
        target: "_blank",
      },
      {
        url: "https://terranteto.hu/vallalat/impresszum/",
        label: "Impresszum",
        target: "_blank",
      }
    ]
  },
  home: {
    title: "Hány mintaállványt lát maga előtt?",
    choose: "Kérjük, válassza ki a megfelelőt!",
    description: "Üdvözöljük a virtuális mintaállványok világában!<br>A legfontosabb tetőcserép-információk egy helyen."
  },
  stands: {
    title: "Üdvözöljük a virtuális mintaállványok világában!",
    description: "További információkért kattintson a cserépre!"
  },
  buttons: {
    goBack: "Vissza a főoldalra",
    addToFavourites: "Kedvencekhez adom",
    productSheet: "Tovább a termékadatlapra",
    moreInfo: "Bővebb információ",
    sendEmail: "Elküldöm e-mailben",
    send: "Elküldöm"
  },
  productDetails: {
    priceLabel: "Bruttó hirdetési ár/db",
    salePriceLabel: "Akciós ár/db",
    materialRequirementsLabel: "Anyagszükséglet/m2",
    surfaceLabel: "Felületkezelés",
    guaranteeLabel: "Garancia"
  },
  favourites: {
    title: "Kedvencek",
    sent: 'Az email elküldésre került.'
  },
  errors: {
    serverError: "Hiba történt az adatok betöltése során.<br/>Kérjük, próbálja meg később."
  },
  zenitInfo: "A Terrán Zenit Max márkanevű termék formájában, színeiben, befoglaló méretében megegyezik a korábbi Zenit termékkel, csupán néhány műszaki megoldásban, mint például a vízhorony helye és kialakítása térnek el egymástól."
};
