import { useState, useEffect, Children, cloneElement } from "react";
import { useSwipeable } from "react-swipeable";
import styles from "./Carousel.module.scss";
import classNames from "classnames";

const { module, inner, item, button } = styles;

export function CarouselItem({ children }) {
  return <div className={item}>{children}</div>;
}

export default function Carousel({ children, references, setLastItem }) {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const lastIndex = references.length - 1;

    if (lastIndex === activeIndex) {
      setTimeout(() => {
        setLastItem(true);
      }, 500);
    }

    if (lastIndex !== activeIndex) setLastItem(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex]);

  const updateIndex = newIndex => {
    if (newIndex < 0) {
      newIndex = Children.count(children) - 1;
    } else if (newIndex >= Children.count(children)) {
      newIndex = 0;
    }

    setActiveIndex(newIndex);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => updateIndex(activeIndex + 1),
    onSwipedRight: () => updateIndex(activeIndex - 1),

    preventScrollOnSwipe: true,
    trackMouse: true
  });

  const renderButton = type => {
    return references && <div className={classNames(styles[type], `icon-${type}`)}></div>;
  };

  return (
    <div {...handlers} className={module}>
      <div className={inner} style={{ transform: `translateX(-${activeIndex * 100}%)` }}>
        {Children.map(children, child => {
          return cloneElement(child);
        })}
      </div>
      <button className={button} onClick={() => updateIndex(activeIndex - 1)}>
        {renderButton("previous")}
      </button>
      <button className={button} onClick={() => updateIndex(activeIndex + 1)}>
        {renderButton("next")}
      </button>
    </div>
  );
}
