import { hu } from "i18n/hu";
import Logo from "assets/images/logo.svg";
import Favicon from "assets/images/favicon.svg";
import styles from "./Loader.module.scss";

export default function Loader({ favicon }) {
  return (
    <div className={styles.loader}>
      {favicon ? (
        <img className={styles.favicon} src={Favicon} alt={hu.logo} />
      ) : (
        <img className={styles.logo} src={Logo} alt={hu.logo} />
      )}
    </div>
  );
}
