import { BadgeItem } from "components";
import styles from "./BadgeList.module.scss";

export default function BadgeList({ badges }) {
  return (
    <div className={styles.m}>
      {badges.map((item, index) => (
        <BadgeItem badge={item} key={index} />
      ))}
    </div>
  );
}
