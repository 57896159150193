import { Button } from "components";
import { hu } from "i18n/hu";
import Image from "assets/images/404.svg";
import styles from "./Page404.module.scss";

export default function Page404() {
  return (
    <div className={styles.m}>
      <img src={Image} alt={hu.page404.full} />
      <h1>{hu.page404.title}</h1>
      <h2>{hu.page404.content}</h2>
      <div className={styles.b}>
        <Button label="goBack" link={"/"} />
      </div>
    </div>
  );
}
