import AppProvider from "./AppProvider";
import Router from "./Router";

export default function Providers() {
  return (
    <AppProvider>
      <Router />
    </AppProvider>
  );
}
