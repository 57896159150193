import { useState } from "react";
import { useSwipeable } from "react-swipeable";
import classNames from "classnames";
import styles from "./SwipeBox.module.scss";

export default function SwipeBox({ children, onClose }) {
  const [showOverlay, setShowOverlay] = useState(false);
  const [showBox, setShowBox] = useState(false);

  const { module, overlay, box, animShow, animHide, close, content } = styles;

  setTimeout(() => {
    setShowOverlay(true);
    setShowBox(true);
  }, 500);

  const handleClose = () => {
    setShowBox(false);
    setShowOverlay(false);

    setTimeout(onClose, 500);
  };

  const handlers = useSwipeable({
    onSwipedDown: () => {
      handleClose();
    },

    preventScrollOnSwipe: true,
    trackMouse: true
  });

  return (
    <div className={classNames(module, { [overlay]: showOverlay })}>
      <div
        className={classNames(box, {
          [animShow]: showBox,
          [animHide]: !showBox
        })}
        {...handlers}>
        <div className={content}>{children}</div>
      </div>
      <span className={classNames(close, "icon-previous")} onClick={handleClose}></span>
    </div>
  );
}
