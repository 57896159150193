import { useState } from "react";
import moment from "moment";
import { expirationConfig } from "configs/localStorageExpiration";

export function useLocalStorage(key, initialValue) {
  const isExpired = data => {
    if (!(key in expirationConfig) || !("expires" in data)) {
      return false;
    }

    return moment(data.expires).isBefore(moment());
  };

  const getValue = key => {
    if (typeof window === "undefined") {
      return false;
    }

    try {
      const item = window.localStorage.getItem(key);
      if (!item) {
        return false;
      }

      const data = JSON.parse(item);
      if (isExpired(data)) {
        window.localStorage.removeItem(key);
        return false;
      }

      return data.data;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === "undefined") {
      return initialValue;
    }

    try {
      return getValue(key);
    } catch (error) {
      console.log(error);
      return initialValue;
    }
  });

  const setValue = (k, v) => {
    if (typeof window === "undefined") {
      return;
    }

    try {
      const data = { data: v };
      if (key in expirationConfig) {
        data.expires = moment().add(expirationConfig[key], "seconds").format();
      }
      window.localStorage.setItem(k, JSON.stringify(data));
    } catch (error) {
      console.log(error);
    }
  };

  const storeValue = value => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      setValue(key, valueToStore);
    } catch (error) {
      console.log(error);
    }
  };

  return [storedValue, storeValue];
}
