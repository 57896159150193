import styles from "./Banner.module.scss";

export default function Banner({ banner }) {
  const { link, image, alt } = banner;

  return (
    <a href={link} target="_blank" rel="noreferrer" className={styles.m}>
      <img src={image} alt={alt} />
    </a>
  );
}
