import { useEffect } from "react";
import classNames from "classnames";
import { FavouritesList } from "components";
import { useAppState } from "hooks/appState";
import { useLocalStorage } from "hooks/useLocalStorage";
import styles from "./Favourites.module.scss";

export default function Favourites() {
  const [appState, setAppState] = useAppState();
  const [favourites, setFavourites] = useLocalStorage("favourites", []);

  const { module, dark, counter } = styles;

  useEffect(() => {
    let updated = favourites || [];
    if (favourites && appState.favourites) {
      updated = updated.concat(appState.favourites.filter(item => updated.indexOf(item) < 0));
    }
    setFavourites(updated);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appState.favourites]);

  useEffect(() => {
    if (!favourites.length) {
      setAppState({ swipeBoxVisible: false });
      setTimeout(() => setAppState({ showFavourites: false }), 400);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [favourites]);

  const showFavouritesList = () => {
    setAppState({ swipeBoxVisible: true, showFavourites: true });
  };

  const hideFavouritesList = () => {
    if (!appState.currentProduct) {
      setAppState({ swipeBoxVisible: false, showFavourites: false });
    }
  };

  const removeItem = id => {
    setFavourites(favourites.filter(item => item !== id));
  };

  return (
    <>
      {!!favourites.length && (
        <div className={module} onClick={showFavouritesList}>
          <em className={classNames("icon-favourite", { [dark]: appState.swipeBoxVisible })}></em>
          <span className={counter}>{favourites.length}</span>
        </div>
      )}
      {appState.showFavourites && (
        <FavouritesList callback={hideFavouritesList} favourites={favourites} removeItem={removeItem} />
      )}
    </>
  );
}
