import { Link } from "react-router-dom";
import classNames from "classnames";
import { hu } from "i18n/hu";
import styles from "./Button.module.scss";

export default function Button({ label, link, externalLink, color, onClick = () => {} }) {
  const style = classNames(styles.b, [color === "red" ? styles.r : styles.d]);
  const text = hu.buttons[label];

  if (link) {
    return (
      <Link to={link} className={style} onClick={onClick}>
        {text}
      </Link>
    );
  } else if (externalLink) {
    return (
      <a href={externalLink} target="_blank" rel="noreferrer" onClick={onClick}>
        <span className={style}>{text}</span>
      </a>
    );
  } else
    return (
      <span className={style} onClick={onClick}>
        {text}
      </span>
    );
}
