import { Link } from "react-router-dom";
import classNames from "classnames";
import { hu } from "i18n/hu";
import Logo from "assets/images/logo.svg";
import { useAppState } from "hooks/appState";
import styles from "./Header.module.scss";

export default function Header() {
  /* eslint-disable-next-line no-unused-vars */
  const [appState, setAppState] = useAppState();

  const closeAll = () => {
    setAppState({ closeStand: true });
    setTimeout(() => {
      setAppState({
        stand: null,
        swipeBoxVisible: false,
        currentProduct: null,
        closeStand: false,
        showFavourites: false
      });
    }, 500);
    document.querySelector("html").style.overflow = "initial";
  };

  return (
    <header className={styles.m}>
      <Link to="/" onClick={closeAll}>
        <img src={Logo} alt={hu.logo} />
      </Link>
      {appState.isMobile && (appState.swipeBoxVisible || appState.stand) && (
        <span className={classNames(styles.closeButton, "icon-close")} onClick={closeAll}></span>
      )}
    </header>
  );
}
