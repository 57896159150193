import { StandItem } from "components";
import { hu } from "i18n/hu";
import styles from "./StandList.module.scss";

export default function StandList({ stands }) {
  return (
    <>
      <p className="text-center mb-5 mt-1.5 text-sm" dangerouslySetInnerHTML={{ __html: hu.home.description }} />
      <div className="py-5 px-4 md:-mx-[15px] bg-darkGrey rounded-[10px]">
        <h1 className="text-xl font-semibold font-montserrat max-w-[280px] mb-0.5 text-center mx-auto">
          {hu.home.title}
        </h1>
        <p className="text-sm text-center">{hu.home.choose}</p>
        <div className={styles.l}>
          {stands.map((item, index) => (
            <StandItem key={index} item={item} />
          ))}
        </div>
      </div>
    </>
  );
}
