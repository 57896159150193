import { useEffect } from "react";
import { ToastContainer } from 'react-toastify';
import { useAppState } from "hooks/appState";
import { useApiService } from "hooks/apiService";
import { useLocalStorage } from "hooks/useLocalStorage";
import { Loader, Desktop, Mobile, Footer } from "components";
import styles from "./Home.module.scss";
import 'react-toastify/dist/ReactToastify.css';

export default function Home() {
  const [appState, setAppState] = useAppState();
  const { get } = useApiService();
  const [stands, setStands] = useLocalStorage("stands", []);

  const resizeHandler = () => {
    const isMobile = window.matchMedia("(max-width: 991px)").matches;
    if (isMobile) {
      setAppState({
        stand: null
      });
    }

    setAppState({ isMobile });
  };

  useEffect(() => {
    window.addEventListener("resize", resizeHandler);
    window.dispatchEvent(new Event("resize"));

    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let isLoading = false;

    const fetchStands = async () => {
      if (isLoading) {
        return;
      }
      isLoading = true;
      try {
        const { data: items } = await get("stands");
        setStands(items.data);
        return Promise.resolve();
      } catch (error) {
        console.log(error);
      } finally {
        isLoading = false;
      }
    };

    if (!stands.length) {
      fetchStands();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <main className={styles.c}>
        {appState.isMobile !== null && stands ? (
          <>{appState.isMobile ? <Mobile stands={stands} /> : <Desktop stands={stands} />}</>
        ) : (
          <div className={styles.l}>
            <Loader favicon={true} />
          </div>
        )}
      </main>
      <Footer></Footer>
      <ToastContainer position="bottom-right" closeOnClick theme="colored" autoClose="3000" />
    </>
  );
}
