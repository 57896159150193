import React from "react";

export const initialState = {
  stand: null,
  currentProduct: null,
  favourites: [],
  swipeBoxVisible: false,
  showFavourites: false,
  closeStand: false,
  isMobile: null
};

export const stateAppContext = React.createContext(initialState);
export const setAppContext = React.createContext(undefined);

export function useAppState() {
  return [React.useContext(stateAppContext), React.useContext(setAppContext)];
}

export function useSetAppState() {
  return React.useReducer((state, newValue) => ({ ...state, ...newValue }), initialState);
}
