import { stateAppContext, setAppContext, useSetAppState } from "hooks/appState.js";

export default function AppProvider({ children }) {
  const [appState, setAppState] = useSetAppState();

  return (
    <stateAppContext.Provider value={appState}>
      <setAppContext.Provider value={setAppState}>{children}</setAppContext.Provider>
    </stateAppContext.Provider>
  );
}
