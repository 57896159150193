import { useAppState } from "hooks/appState";
import { BadgeList } from "components";
import useGtmEvent from "hooks/gtmTrack";

import styles from "./StandItem.module.scss";

export default function StandItem({ item }) {
  // eslint-disable-next-line no-unused-vars
  const [appState, setAppState] = useAppState();
  const { trackEvent } = useGtmEvent();

  const clickHandler = () => {
    setAppState({ stand: item });
    document.querySelector("html").style.overflow = "hidden";

    trackEvent({
      event: "StandSelected",
      category: "állvány mérete",
      action: "kiválasztás",
      label: item.name
    });
  };

  return (
    <div className={styles.m} onClick={() => clickHandler()}>
      {item.image && <img src={item.image} alt={item.name} />}
      <span className={styles.n}>{item.name}</span>
      <span className={styles.d}>{item.description}</span>
      {item.badges && <BadgeList badges={item.badges} />}
    </div>
  );
}
