import { useState, useEffect, useMemo } from "react";
import { useSwipeable } from "react-swipeable";
import classNames from "classnames";
import { hu } from "i18n/hu";
import styles from "./Onboarding.module.scss";

export default function Onboarding({ setOnboarding }) {
  const [currentStep, setCurrentStep] = useState();
  const [step, setStep] = useState(0);
  const [showBar, setShowBar] = useState(false);
  const [showInstruction, setShowInstruction] = useState(false);
  const [containerHeight, setContainerHeight] = useState();

  const { module, wrapper, button, bar, positionIcon, animSwipe, animTouch, animBar } = styles;
  const $tiles = document.querySelector(".ProductListItem");

  useEffect(() => {
    if (showBar) {
      setTimeout(() => setShowInstruction(true), 1000);
    }
  }, [showBar]);

  useEffect(() => {
    const tilesInCol = 5;
    const tileHeight = $tiles?.clientHeight || 90;

    let containerHeight = tilesInCol * tileHeight;

    if (window.innerWidth >= 500) {
      containerHeight = containerHeight + Math.round(tileHeight * 0.55);
    }

    setContainerHeight(containerHeight);
  }, [$tiles]);

  const steps = useMemo(
    () => [
      {
        id: "swipe",
        icon: "icon-swipe",
        text: hu.onBoarding.swipe,
        animation: animSwipe
      },
      {
        id: "touch",
        icon: "icon-touch",
        text: hu.onBoarding.touch,
        animation: animTouch
      }
    ],
    [animSwipe, animTouch]
  );

  useEffect(() => {
    handleSteps(steps[step]);
  }, [step, steps]);

  const handleSteps = step => {
    setShowInstruction(false);
    setShowBar(false);

    setTimeout(() => setCurrentStep(step), 500);
    setTimeout(() => setShowBar(true), 1000);
  };

  const handleClick = () => {
    if (currentStep?.id === "touch") {
      setShowBar(false);
      setShowInstruction(false);

      setTimeout(() => setOnboarding(true), 500);
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      setStep(1);
    },
    onSwipedRight: () => {
      setStep(1);
    },
    preventScrollOnSwipe: true,
    trackMouse: true
  });

  return (
    <div className={module} {...handlers}>
      {currentStep &&
        steps.map(step => {
          const { id, icon, animation } = step;

          return (
            id === currentStep.id && (
              <div key={id}>
                <div className={wrapper} style={{ height: `${containerHeight}px` }} onClick={handleClick}>
                  <button className={classNames(button, { [animation]: showInstruction })}>
                    <em className={classNames(icon, [currentStep.id === "swipe" ? positionIcon : ""])}></em>
                  </button>
                </div>
                <div className={classNames(bar, { [animBar]: showBar })}>{currentStep.text}</div>
              </div>
            )
          );
        })}
    </div>
  );
}
