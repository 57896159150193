import { useEffect } from "react";
import { useAppState } from "hooks/appState";
import { Stands, Favourites } from "components";
import { desktopStandId } from "configs/stands";

export default function Desktop({ stands }) {
  // eslint-disable-next-line no-unused-vars
  const [appState, setAppState] = useAppState();

  useEffect(() => {
    setAppState({ stand: stands.find(item => item.id === desktopStandId) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Stands />
      <Favourites />
    </>
  );
}
