import { Stands, StandList, Favourites } from "components";

export default function Mobile({ stands }) {
  return (
    <>
      <StandList stands={stands} />
      <Stands />
      <Favourites />
    </>
  );
}
