import { useEffect, useState } from "react";
import classNames from "classnames";
import { standsConfig } from "configs/stands";
import { useLocalStorage } from "hooks/useLocalStorage";
import { useAppState } from "hooks/appState";
import { useApiService } from "hooks/apiService";
import { hu } from "i18n/hu";
import { banners } from "configs/banners";
import { StandsItem, Onboarding, ProductDetails, ProductListItem, Banner } from "components";
import styles from "./Stands.module.scss";

export default function Stands() {
  const { get } = useApiService();
  const [appState, setAppState] = useAppState();
  const [products, setProducts] = useLocalStorage("products", []);
  const [variations, setVariations] = useState([]);
  const [hasVariations, setHasVariations] = useState(false);
  const [onBoarding, setOnBoarding] = useLocalStorage("onBoarding", false);

  useEffect(() => {
    const repeatVariations = () => {
      if (!appState?.stand?.id || !products) {
        return;
      }

      const stand = standsConfig[appState.stand.id];
      const results = [];

      for (let i = 0; i < stand.stands; i++) {
        results.push([]);
        stand[products.length][i].forEach(item => {
          results[i].push(products[item]);
        });
      }

      // return results;
      setVariations(results);
      setHasVariations(true);
    };

    repeatVariations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products, appState.stand]);

  useEffect(() => {
    let isLoading = false;

    const repeatVariations = data => {
      const stand = standsConfig[appState.stand.id];
      const results = [];

      for (let i = 0; i < stand.stands; i++) {
        results.push([]);
        stand[data.length][i].forEach(item => {
          results[i].push(data[item]);
        });
      }

      setVariations(results);
      setHasVariations(true);
    };

    const fetchVariations = async () => {
      if (isLoading || !appState?.stand?.id) {
        return;
      }

      try {
        if (products) {
          repeatVariations(products);
        } else {
          const { data: variations } = await get(`stands/${appState.stand.id}`);
          setProducts(variations.data);
          repeatVariations(variations.data);
        }
      } catch (error) {
        console.log(error);
      } finally {
        isLoading = false;
      }
    };

    fetchVariations();
    // eslint-disable-next-line
  }, [appState.stand]);

  const closeStand = () => {
    if (hasVariations) {
      setHasVariations(false);
      setTimeout(() => {
        setVariations([]);
        setAppState({ stand: null, swipeBoxVisible: false, closeStand: false });
      }, 500);
      document.querySelector("html").style.overflow = "initial";
    }
  };

  if (appState?.closeStand) {
    closeStand();
  }

  if (!appState?.stand?.id || !variations) {
    return;
  }

  return (
    <>
      <div className={classNames(styles.m, { [styles.active]: hasVariations })}>
        {!appState.isMobile && (
          <div className={styles.lead}>
            <h1>{hu.stands.title}</h1>
            <p>{hu.stands.description}</p>
          </div>
        )}
        <div className={styles.stands}>
          {variations.map((item, index) => (
            <div className={styles.item}>
              {!appState.isMobile && <Banner banner={banners.hu[index]} />}
              <StandsItem key={index} item={item}>
                {item.map((variation, num) => (
                  <ProductListItem key={num} item={variation} />
                ))}
              </StandsItem>
            </div>
          ))}
        </div>
        {appState.isMobile && (
          <span className={classNames(styles.closeButton, "icon-previous")} onClick={closeStand}></span>
        )}
      </div>
      {!onBoarding && hasVariations && appState.isMobile && <Onboarding setOnboarding={setOnBoarding} />}
      {appState.currentProduct && <ProductDetails />}
    </>
  );
}
