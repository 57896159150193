import { useEffect, useState } from "react";
import { useApiService } from "hooks/apiService";
import { Loader, SwipeBox, FavouritesListItem, SendFavourites } from "components";
import { hu } from "i18n/hu";

export default function FavouritesList({ callback, favourites, removeItem }) {
  const { get } = useApiService();
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchFavourites = async () => {
      if (isLoading) {
        return;
      }

      setData([]);
      setHasError(false);
      setIsLoading(true);

      try {
        const { data: results } = await get("favourites", null, {
          items: favourites
        });
        setData(results.data);
        return Promise.resolve();
      } catch (error) {
        console.log(error);
        setHasError(true);
        return Promise.reject();
      } finally {
        setIsLoading(false);
      }
    };

    fetchFavourites();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setData(data.filter(item => favourites.includes(item.id)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [favourites]);

  return (
    <SwipeBox onClose={callback}>
      {isLoading && (
        <div className="mt-10">
          <Loader favicon={true} />
        </div>
      )}
      {hasError && <div dangerouslySetInnerHTML={{ __html: hu.errors.serverError }}></div>}
      {data.length > 0 && (
        <>
          <h1>{hu.favourites.title}</h1>
          {data.map(item => (
            <FavouritesListItem key={item.id} {...item} removeItem={removeItem} />
          ))}
          <SendFavourites favourites={favourites} />
        </>
      )}
    </SwipeBox>
  );
}
