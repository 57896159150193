import styles from "./BadgeItem.module.scss";

export default function BadgeItem({ badge }) {
  return (
    <div className={styles.m}>
      <img src={badge.icon} alt={badge.label} />
      <span className={styles.l}>{badge.label}</span>
    </div>
  );
}
