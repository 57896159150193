import { useRef, useState } from "react";
import { toast } from 'react-toastify';
import { useApiService } from "hooks/apiService";
import useGtmEvent from "hooks/gtmTrack";
import { Button } from "components";
import styles from "./SendFavourites.module.scss";
import { hu } from "i18n/hu";

export default function SendFavourites({ favourites }) {
  const [showInput, setShowInput] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const email = useRef(null);
  const { save } = useApiService();
  const { trackEvent } = useGtmEvent();

  const sendEmail = async () => {
    if (isLoading || !email.current.value) {
      return;
    }

    setIsLoading(true);

    try {
      await save("favourites", {
        favourites,
        email: email.current.value
      });

      toast.success(hu.favourites.sent)

      setShowInput(false);

      trackEvent({
        event: "SendFavourites",
        category: "Kedvencek",
        action: "email küldés"
      });

      return Promise.resolve();
    } catch (error) {
      console.log(error);
      return Promise.reject();
    } finally {
      setIsLoading(false);
    }
  };

  const buttonTrigger = async event => {
    event.preventDefault();

    if (showInput) {
      await sendEmail();
    } else {
      setShowInput(true);
    }
  };

  return (
    <form onSubmit={buttonTrigger} className={styles.m}>
      {showInput && (
        <div className="text-left mb-3">
          <label htmlFor="email">E-mail</label>
          <input
            id="email"
            type="email"
            ref={email}
            className="border-2 w-full py-2 px-4"
            required
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,63}$"
          />
          <input type="checkbox" id="gdpr" className={styles.checkbox} required />
          <span htmlFor="gdpr">
            Elfogadom az{" "}
            <a href="https://terranteto.hu/adatkezelesi-szabalyzat/" className="underline">
              Adatkezelési tájékoztatót.
            </a>
          </span>
        </div>
      )}
      <button className={styles.button}>
        <Button label={showInput ? "send" : "sendEmail"} />
      </button>
    </form>
  );
}
